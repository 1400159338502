.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #E23844;
  border: 1px solid #E23844;
  border-radius: 0px;
  transform: translateY(-0.075em);
}

input[type="checkbox"]:checked::before {
  content: '\2713';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #E23844;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 1;
  color: white;
}
.background{
  background-image: url('./assets/images/login-left.svg');
}