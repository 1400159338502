@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700;800&display=swap');


body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Outfit', sans-serif;
}

.text-input:focus {
  outline: none;
}


.input-container .filled,
  .input-container:focus-within label {
    top: -11px;
    left: 10px;
    transition: all 200ms ease;
    font-size: 12px;
    font-weight: 500;
    right: auto;
  }

  .input-container label {
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: 14px;
    transition: all 200ms ease;
  }